import { FC, useEffect } from "react";
import jwt_decode from 'jwt-decode';

import { postData } from "services/ApiActions";

export type Token = {
  id_token: string;
}

export type Params = {
  code?: string;
}

export type JWTToken = {
  family_name: string;
  given_name: string;
  preferredMail: string;
  sub: string;
  uid: string;
  country: string;
  exp: number;
}

export enum TokenStatus {
  OK = 0,
  EXPIRED = 1,
  NO_TOKEN = 2
}

export const getParameters = (queryString: string): Params => {
  let params = {};
  let pairs = queryString.substring(1).split("&");

  pairs.forEach(m => {
    let vals = m.split("=");
    Object.assign(params, { [vals[0]]: vals[1] });
  });
  return params;
}

export const checkIfExpired = () => {
  var token: string | null = localStorage.getItem("id_token");
  if(!token) return TokenStatus.NO_TOKEN;
  var decodedToken: JWTToken = jwt_decode(token);
  var currentTime = Math.round((new Date()).getTime() / 1000);
  return (decodedToken.exp - currentTime) > 0
    ? TokenStatus.OK
    : TokenStatus.EXPIRED;
}
export const refreshToken = async () => {
  let response = await postData<object, Token>(`refresh`, {});
  if(!response.isError && response.data?.id_token)
  {
    localStorage.setItem("id_token", response.data?.id_token);
  } else
  {
    window.location.assign("/login");
  }
}

const Authentication: FC = () => {
  useEffect(() => {
    let status = checkIfExpired();
    switch (status) {
      case TokenStatus.NO_TOKEN:
        window.location.assign("/login");
        break;
      case TokenStatus.EXPIRED:
        window.location.assign("/login");
        break;
      case TokenStatus.OK:
        break;
    }
  });
  return null;
}
export default Authentication;