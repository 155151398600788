export type StepItem = {
  label: string;
  description: string;
  instructions: string;
  completed: boolean;
  skip: false;
};

export type Client = {
  jobId: string;
  isNew: boolean;
  isTest: boolean;
  clientName?: string;
  businessId?: string;
  dataClause: boolean;
  details: {
    address?: string;
    postCode?: string;
    postArea?: string;
    country?: string;
  };
  periodStart?: Date;
  periodEnd?: Date;
  preparer?: string;
  reviewer?: string;
};
export type CCHData = {
  useTemplate: boolean;
  entity?: Entity;
  workbook?: Workbook;
  template?: Template;
};
export type Entity = {
  entityId: number;
  name: string;
};
export type Workbook = {
  entityId: number;
  workbookId: number;
  name: string;
};
export type Template = {
  entityId: number;
  templateId: number;
  name: string;
};
export type CCHItem = {
  key: string;
  value: boolean | Template | Workbook | Entity | undefined;
};
export type ShareFiles = {
  cleanedTB?: File;
  rawTB?: File;
};
export type Category = {
  categoryId: string;
  name: string;
  category: string;
  subCategory: string;
  code: string;
};
export type AIResult = {
  resultId: string;
  sessionId: string;
  accountId: string;
  result: string;
  confidence: number;
  categoryId?: string;
};
export type Account = {
  accountId: string;
  sessionId: string;
  number: string;
  account: string;
  value: number;
  result?: AIResult;
};
export type BalanceSession = {
  balanceSessionId: string;
  businessId?: string;
  jobId?: string;
  clientName?: string;
  dataClause: boolean;
  useTemplate: boolean;
  isNew: boolean;
  isTest?: boolean;
  isSubmitted: boolean;
  status: number;
  entity?: string;
  workbook?: string;
  template?: string;
  periodStart?: string;
  periodEnd?: string;
  preparer?: string;
  reviewer?: string;
  userId?: string;
  address?: string;
  createdAt?: string;
  statusupdates?: StatusUpdate[];
};
export type StatusUpdate = {
  statusId: number;
  sessionId: string;
  createdAt: string;
  status: RobotStatus;
  error?: string;
};
export enum RobotStatus {
  'Session created' = 0,
  'Not assigned' = 1,
  'Create client' = 2,
  'Create workbook' = 3,
  'Upload TB to CCH' = 4,
  'Use mapping template' = 5,
  'Mapping' = 6,
  'Done' = 7,
  'Failed' = 8,
  'Abandoned' = 99,
}
export type Tokens = {
  idToken?: string;
  accessToken?: string;
};
export interface ISessionStore {
  sessionId: string;
  clientData: Client;
  cchData: CCHData;
  files: ShareFiles;
  entities: Entity[];
  workbooks: Workbook[];
  templates: Template[];
  categories: Category[];
  accounts: Account[];
  sessions: BalanceSession[];
  steps: StepItem[];
  getBalanceSession: BalanceSession;
  fetchClientData: (jobId: string) => void;
  fetchAccountResults: (sessionId: string) => void;
  fetchEntities: () => void;
  fetchWorkbooks: (entityId: number) => void;
  fetchTemplates: () => void;
  fetchCategories: () => void;
  fetchSessions: () => void;
  updateClient: (key: string, value: string | Date | boolean, detail: boolean) => void;
  updateCCH: (item: CCHItem) => void;
  updateStep: (id: number, key: string, value: string | boolean) => void;
  updateFile: (key: string, file: File) => void;
  updateAIResult: (result: AIResult) => void;
  updateSessionId: (sessionId: string) => void;
  updatePreparer: () => void;
  abandonSession: (sessionId: string) => Promise<boolean>;
  postAll: (sessionId: string) => Promise<boolean>;
}

export interface StoreProps {
  shareStore: ISessionStore;
}
