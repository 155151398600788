export type Header = {
  id: string,
  label: string,
  numeric: boolean,
  sortable: boolean
}

export type Order = 'asc' | 'desc'

export enum ErrorLevel {
  INFO = 0,
  WARNING,
  ERROR,
}

export type ValidationError = {
  message: string,
  level: ErrorLevel
}

export type TokenPayload = {
  upn?: string,
  sub?: string,
  uid?: string,
  name?: string
}

export type ResponseObject<T> ={
  isError: boolean,
  error?: {
    status_code?: number,
    message?: string
  },
  data: T | null
}
export enum LoadState {
  INIT,
  LOADING,
  READY,
  ERROR,
}