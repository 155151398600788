import React, { FC } from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import { ErrorLevel, ValidationError } from 'types/Common'

interface ValidationLogProps {
  errors: ValidationError[]
}

const getSeverity = (level: ErrorLevel) => {
  switch(level)
  {
    case ErrorLevel.INFO:
      return "info"
    case ErrorLevel.WARNING:
      return "warning"
    case ErrorLevel.ERROR:
      return "error"
  }
}

const ValidationLog: FC<ValidationLogProps> = ({ errors }) => (
  <>
    {
      errors.map((error, index) => (
        <MuiAlert key={`error-${index}`} severity={getSeverity(error.level)} style={{ marginTop: 16 }}>
          {error.message}
        </MuiAlert>
      ))
    }
    {errors.length === 0 && 
      <MuiAlert severity="success" style={{ marginTop: 16 }}>File OK!</MuiAlert>
    }
  </>
)

export default ValidationLog
