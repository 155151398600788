import { BalanceSession } from 'types/Store';
import { ResponseObject } from 'types/Common';
import { STORAGE_KEYS } from '@pwc-fi/ui-library';
import { AxiosRequestConfig } from 'axios';

export type ApiResponse<T> = {
  isError?: boolean;
  message?: string;
  responseException?: string;
  result: T | null;
  statusCode: number;
  version?: string;
}

const axios = require('axios').default

const instance = axios.create({
  baseURL: process.env.REACT_APP_API || "",
  timeout: 90000
});

instance.interceptors.request.use((request: AxiosRequestConfig) => {
  request.headers['Authorization'] = `Bearer ${localStorage.getItem(
    STORAGE_KEYS.TOKEN
  )}`;
  return request;
});

export const getData = <T>(endpoint: string): ResponseObject<T> =>  {
  return instance.get(endpoint)
    .then((response: any) => response.data)
    .catch((error: Error) => {
      return ({isError: true, ...error, result: []})
    })
}

export const postData = <T, U>(endpoint: string, data: T): ResponseObject<U> => {
  return instance.post(endpoint, data)
    .then((response: any) => response.data)
    .catch((error: Error) => {
      return ({isError: true, ...error})
    })
}

export const putData = <T, U>(endpoint: string, data: T): ResponseObject<U> => {
  return instance.put(endpoint, data)
    .then((response: any) => response.data)
    .catch((error: Error) => {
      return ({isError: true, ...error})
    })
}
export const deleteData = <T>(endpoint: string): ResponseObject<T> => {
  return instance.delete(endpoint)
    .then((response: any) => response.data)
    .catch((error: Error) => {
      return ({isError: true, ...error})
    })
}


export const createSession = async (session: BalanceSession) => {
  var response = await postData<BalanceSession, ResponseObject<BalanceSession>>(
    `/session/create`,
    session
  );
  return !response?.isError;
};
export const uploadFile = async (sessionId: string, type: string, file: File | undefined) => {
  if (!file) return false;
  let formData = new FormData();
  formData.append('file', file);
  var response = await postData(`/session/upload/${sessionId}/${type}`, formData);
  return !response?.isError;
};
export const processAI = async (sessionId: string) => {
  var response = await postData(`/session/ai/${sessionId}`, {});
  return !response?.isError;
};
