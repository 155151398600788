import { LinearProgress, Button } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { createSession, uploadFile, processAI } from 'services/ApiActions';
import { ShareFiles, BalanceSession } from 'types/Store';

const DELAY = 2500;

interface SyncViewProps {
  sessionId: string;
  files: ShareFiles;
  getBalanceSession: BalanceSession;
  complete: (status: boolean) => void;
}

const progressSteps = [
  'Initialising',
  'Creating session',
  'Uploading TB and saving data',
  'Uploading raw TB file',
  'Processing AI results',
  'All done!',
];

const SyncView: FC<SyncViewProps> = ({ sessionId, getBalanceSession, files, complete }) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);
  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  const progression = useCallback(async () => {
    switch (progress) {
      case 0:
        sleep(DELAY).then(() => setProgress(1));
        break;
      case 1:
        let payload = getBalanceSession;
        let result = await createSession(payload);
        if (result) sleep(DELAY).then(() => setProgress(2));
        else setError(true);
        break;
      case 2:
        if (await uploadFile(sessionId, 'TB', files.cleanedTB))
          sleep(DELAY).then(() => (!files.rawTB ? setProgress(4) : setProgress(3)));
        else setError(true);
        break;
      case 3:
        if (await uploadFile(sessionId, 'rawTB', files.rawTB))
          sleep(DELAY).then(() => setProgress(4));
        else setError(true);
        break;
      case 4:
        if (await processAI(sessionId))
          sleep(DELAY).then(() => {
            setProgress(5);
            complete(true);
          });
        else setError(true);
        break;
    }
  }, [complete, files, getBalanceSession, progress, sessionId]);
  const getProgress = () => Math.floor(((progress + 1) / progressSteps.length) * 100);
  useEffect(() => {
    if (progress < 5) progression();
  }, [progress, progression]);
  return (
    <>
      {
        <>
          <h3>Syncing data</h3>
          <LinearProgress variant="determinate" value={getProgress()} />
          <p style={{ textAlign: 'center' }}>
            {progressSteps[progress]} {error && `(request failed)`}
          </p>
          {error && (
            <Button
              onClick={() => {
                setError(false);
                progression();
              }}
            >
              Try again
            </Button>
          )}
        </>
      }
    </>
  );
};

export default SyncView;
