import { Button } from '@material-ui/core';
import fileDownload from 'js-file-download';
import React, { useEffect, useState } from 'react';
import { unparseAccounts } from 'services/Common';
import { LoadState } from 'types/Common';
import { Account, Category } from 'types/Store';

const ExportButton: React.FC<{ sessionId: string, filename: string, accounts: Account[], categories: Category[], fetchAccountResults: (sessionId: string) => void }> = ({ sessionId, accounts, categories, filename, fetchAccountResults }) => {
  const [state, setState] = useState<LoadState>(LoadState.INIT)
  useEffect(() => {
    setState(accounts.length > 0 && accounts[0].sessionId.toLowerCase() === sessionId.toLowerCase() 
    ? LoadState.READY 
    : LoadState.INIT)
  }, [ accounts, sessionId ])
  return(
    <Button disableElevation color="secondary" variant="contained" style={{ marginRight: 8 }} onClick={() => {
      if(state === LoadState.READY)
      {
        let excel = unparseAccounts(accounts, categories);

        fileDownload(excel, `mapping-${filename}.xlsx`);
      }
      else if(state === LoadState.INIT) 
      {
        setState(LoadState.LOADING)
        fetchAccountResults(sessionId)
      }
    }}>
      {state === LoadState.INIT ? "Export mapping" : state === LoadState.LOADING ? "Processing" : "Download mapping"}
    </Button>
  )
}
export default ExportButton;