import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';

import { BalanceSession, ISessionStore, RobotStatus, StoreProps } from 'types/Store';
import { Authenticate, PageHeader } from '@pwc-fi/ui-library';
import { Redirect } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import { StatusGradient, useStyles } from 'styles/Default';
import { handleDate } from 'services/Common';
import { Add, HelpOutline } from '@material-ui/icons';
import StatusView from 'components/status/StatusView';
import Authentication from 'components/Authentication/Authentication';

interface MatchParams {
  id: string;
}

const Container: React.FC<StoreProps & RouteComponentProps<MatchParams>> = ({
  shareStore,
  location,
  match,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [filter, setFilter] = useState("")
  const history = useHistory();
  useEffect(() => {
    if(!loaded)
    {
      shareStore.fetchSessions()
      setLoaded(true)
    }
  }, [ loaded, shareStore]);

  return (
    <>
      <Authentication/>
      <PageHeader
        homePage
        title="Project share"
        actionContent={
          <>
            <Button
              startIcon={<Add />}
              color="primary"
              variant="outlined"
              size="large"
              disableElevation
              href="/"
            >
              Create new
            </Button>
          </>
        }
      >
        <p>On this page you can view your previously added CIT processes.</p>
        <Button
          variant="outlined"
          startIcon={<HelpOutline />}
          color="primary"
          disableElevation
          size="large"
          href="https://docs.google.com/presentation/d/1WT0YGhGKzwCaerX3A8M-CPXtSja8AUj-oZA63WY2TRk/edit?usp=sharing"
          target="_blank"
          style={{ marginBottom: 20 }}
        >
          Instructions
        </Button>
      </PageHeader>
      { !loaded
        ? <Paper elevation={0} square style={{ padding: 16 }}>
            <LinearProgress color="primary" />
          </Paper>
        : <>
          <Grid container spacing={2}>
            <Grid item xs={3}>
            <FormControl
              style={{ width: 200, marginBottom: 8 }}
              variant="outlined"
              size="small"
            >
              <Select
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value as string);
                  history.push(`/status/`)
                }}
                defaultValue={filter}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="0">Created</MenuItem>
                <MenuItem value="1">In progress</MenuItem>
                <MenuItem value="7">Done</MenuItem>
                <MenuItem value="8">Failed</MenuItem>
                <MenuItem value="99">Abandoned</MenuItem>
              </Select>
            </FormControl>
            </Grid>
          </Grid>
          <div>
          {
            shareStore.sessions.filter(f => 
              filter.length > 0 
              ? filter === "1" 
                ? f.status > 0 && f.status < 7 
                : f.status === parseInt(filter) 
              : f).map(session => 
                <StatusItem
                  id={match.params.id}
                  session={session}
                  store={shareStore}
                />)
          }
          </div>
        </>
      }
    </>
  );
};

const StatusItem: React.FC<{ session: BalanceSession, store: ISessionStore, id: string }> = ({ session, store, id }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
      <Accordion
        square
        onClick={() => history.push(`/status/${session.balanceSessionId}`)}
        expanded={id === session.balanceSessionId}
        TransitionProps={{ unmountOnExit: true }}
        key={session.balanceSessionId}
      >
        <AccordionSummary>
          <Grid container alignItems="center" spacing={2} className={classes.sessionGrid}>
            <Grid item xs={2}>
              <b>{session.clientName}</b>
              <br />
              {session.address}
            </Grid>
            <Grid item xs={2}>
              {handleDate(session.createdAt)}
            </Grid>
            <Grid item xs={2}>
              <Chip
                label={RobotStatus[session.status]}
                className={classes.sessionGrid}
                style={{ 
                  backgroundColor: session.status === RobotStatus.Abandoned 
                  ? "#000000"
                  : StatusGradient[session.status + 5],
                  color: session.status === RobotStatus.Abandoned 
                  ? "#ffffff"
                  : "#000000"
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {!session.isSubmitted && <Chip label="Not submitted" className={classes.sessionGrid} color="secondary"/>}
              {session.isNew && <Chip label="New client" className={classes.sessionGrid} />}
              {session.isTest && <Chip label="Test" className={classes.sessionGrid} />}
              {session.useTemplate && (
                <Chip label="Use template" className={classes.sessionGrid} />
              )}
              {session.dataClause && <Chip label="Data clause" className={classes.sessionGrid} />}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <StatusView
            accounts={store.accounts}
            session={session}
            store={store}
          />
        </AccordionDetails>
      </Accordion>
  )
}
export const StatusContainer = inject('shareStore')(observer(Container));
