import { Grid } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { validate } from 'services/Common';
import { ShareFiles } from 'types/Store';
import { ErrorLevel, ValidationError } from 'types/Common';
import Dropzone from './Dropzone';
import ValidationLog from './ErrorLog';

interface UploadViewProps {
  files: ShareFiles;
  updateFile: (key: string, file: File) => void;
  complete: (status: boolean) => void;
}
const UploadView: FC<UploadViewProps> = ({ complete, files, updateFile }) => {
  const [errors, setErrors] = useState(new Array<ValidationError>());
  useEffect(() => {
    if (files.cleanedTB) complete(true);
    else complete(false);
  }, [files, complete]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <h4>Cleaned trial balance file (.csv)</h4>
          <Dropzone
            files={files?.cleanedTB && [files.cleanedTB]}
            onChange={(fileArray) => {
              if (files.cleanedTB !== fileArray[0]) updateFile('cleanedTB', fileArray[0]);
            }}
            validator={async (file) => {
              let tempIssues = await validate(file);
              setErrors(tempIssues);
              if (tempIssues.length > 0) {
                return tempIssues.map((m) => m.level).includes(ErrorLevel.ERROR) ? false : true;
              } else {
                return true;
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <h4>Raw trial balance file (any format)</h4>
          <Dropzone
            files={files?.rawTB && [files.rawTB]}
            onChange={(fileArray) => {
              if (files.rawTB !== fileArray[0]) updateFile('rawTB', fileArray[0]);
            }}
          />
        </Grid>
      </Grid>
      {errors.length > 0 && <ValidationLog errors={errors} />}
    </>
  );
};

export default UploadView;
