import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { PwCMaterialTheme } from '@pwc-fi/ui-library';
import { Provider } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import SessionStore from 'store/sessionStore';
import { Layout } from './components/Layout';
import './index.css'

const shareStore = new SessionStore();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={PwCMaterialTheme}>
      <CssBaseline />
      <Provider shareStore={shareStore}>
        <Layout />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
