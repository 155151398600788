import React from 'react';
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@material-ui/core";
import { handleDate } from "services/Common";
import { useStyles } from "styles/Default";
import { BalanceSession, ISessionStore, Account, RobotStatus } from "types/Store";
import ExportButton from "./ExportButton";

const StatusView: React.FC<{ session: BalanceSession, store: ISessionStore, accounts: Account[]}> = ({ session, store, accounts}) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={2} className={classes.sessionGrid} >
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <b>JobId</b>
            </Grid>
            <Grid item xs={9}>
              {session.jobId}
            </Grid>
            <Grid item xs={3}>
              <b>Client</b>
            </Grid>
            <Grid item xs={9}>
              {session.clientName} ({session.businessId})
            </Grid>
            <Grid item xs={3}>
              <b>Period</b>
            </Grid>
            <Grid item xs={9}>
              {handleDate(session.periodStart)} - {handleDate(session.periodEnd)}
            </Grid>
            <Grid item xs={3}>
              <b>Preparer</b>
            </Grid>
            <Grid item xs={9}>
              {session.preparer}
            </Grid>
            {session.reviewer && (
              <>
                <Grid item xs={3}>
                  <b>Reviewer</b>
                </Grid>
                <Grid item xs={9}>
                  {session.reviewer}
                </Grid>
              </>
            )}
            {!session.isNew && (
              <>
                <Grid item xs={3}>
                  <b>Entity</b>
                </Grid>
                <Grid item xs={9}>
                  {session.entity}
                </Grid>
                <Grid item xs={3}>
                  <b>Workbook</b>
                </Grid>
                <Grid item xs={9}>
                  {session.workbook}
                </Grid>
              </>
            )}
            {session.useTemplate && (
              <>
                <Grid item xs={3}>
                  <b>Template</b>
                </Grid>
                <Grid item xs={9}>
                  {session.template}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Timestamp</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Error</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {session.statusupdates?.map((update) => (
                  <TableRow>
                    <TableCell>{update.createdAt}</TableCell>
                    <TableCell width={200}>{RobotStatus[update.status]}</TableCell>
                    <TableCell>{update.error}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ marginTop: 32, textAlign: 'right', verticalAlign: "bottom" }}>
            {
              !session.isSubmitted && 
              <Button style={{ marginRight: 8 }} disableElevation variant="contained" href={`/accounts/${session.balanceSessionId}`}>
                Edit mapping
              </Button>
            }
            <ExportButton 
              sessionId={session.balanceSessionId}
              accounts={accounts}
              categories={store.categories}
              filename={session.clientName || "client"}
              fetchAccountResults={(sessionId) => store.fetchAccountResults(sessionId)}
            />
            {
              session.status !== 99 &&
              <Button disableElevation color="secondary" variant="contained" onClick={ () => window.confirm("Are you sure you want to abandon this session?") && store.abandonSession(session.balanceSessionId) }>
                Abandon
              </Button>
            }
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default StatusView;