import { Paper, LinearProgress } from '@material-ui/core';
import { getParameters, Token } from 'components/Authentication/Authentication';
import React, { FC, useEffect } from 'react';
import { postData } from 'services/ApiActions';

const LoginContainer: FC = () => {
  const queryString = window.location.search;
  useEffect(() => {
    async function getJWT(code: string) {
        let response = await postData<object, Token>(`authorisation/access/${code}`, {});
        if(!response.isError && response.data?.id_token)
        {
          localStorage.setItem("id_token", response.data?.id_token);
          window.location.assign("/");
        }
    }
    let params = getParameters(queryString);
    if(params.code) {
      getJWT(params.code);
    } else {
      if(process.env.REACT_APP_PwCID_URI)
        window.location.assign(process.env.REACT_APP_PwCID_URI);
    }
  });
  return (
    <>
      <Paper elevation={0} square style={{ padding: 16 }}>
        <LinearProgress color="primary" />
        <h2>Logging you in...</h2>
      </Paper>
    </>
  );
};

export default LoginContainer;