import { FormControlLabel, Grid, Switch, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FC, useEffect, useState } from 'react';
import { Client, CCHData, Entity, Workbook, Template, CCHItem } from 'types/Store';

interface CCHViewProps {
  clientData: Client;
  cchData: CCHData;
  entities: Entity[];
  workbooks: Workbook[];
  templates: Template[];
  fetchEntities: () => void;
  fetchTemplates: () => void;
  fetchWorkbooks: (entityId: number) => void;
  updateClient: (key: string, value: string | boolean | Date, detail: boolean) => void;
  updateCCH: (items: CCHItem) => void;
  complete: (status: boolean) => void;
}

const CCHView: FC<CCHViewProps> = ({
  clientData,
  cchData,
  entities,
  templates,
  fetchEntities,
  fetchTemplates,
  fetchWorkbooks,
  updateClient,
  updateCCH,
  complete,
  workbooks,
}) => {
  useEffect(() => {
    if (entities.length === 0) fetchEntities();
  }, [entities, fetchEntities]);
  const [data, setData] = useState({
    entity: cchData.entity || null,
    workbook: cchData.workbook || null,
    template: cchData.template || null,
    isNew: clientData.isNew,
    useTemplate: cchData.useTemplate,
  });

  useEffect(() => {
    let check =
      (data.isNew ? true : data.entity && data.workbook ? true : false) &&
      (data.useTemplate ? (data.template ? true : false) : true);
    if (check) complete(true);
    else complete(false);
  }, [data, complete]);

  // const handleEmail = (prop: string, detail: boolean = false) => (e: React.ChangeEvent<HTMLInputElement>) => {
  //   let value = e.target.value
  //   switch(prop)
  //   {
  //     case 'preparer':
  //       setValidPreparer(validateEmail(value))
  //       break
  //     case 'reviewer':
  //       setValidReviewer(validateEmail(value))
  //       break
  //   }
  //   updateClient(prop, e.target.value, detail)
  // }
  return (
    <>
      <Grid spacing={2} container style={{ marginBottom: '1em' }}>
        <Grid item xs={4}>
          <p>
            Does the client already exist on CCH? <br />
            <b>Please also select the previous workbook, if you want to roll forward it.</b>
          </p>
          <FormControlLabel
            control={
              <Switch
                checked={data.isNew}
                onChange={() => {
                  setData({ ...data, isNew: !data.isNew, entity: null, workbook: null });
                  updateClient('isNew', !clientData.isNew, false);
                  if(!data.isNew)
                  {
                    updateCCH({ key: 'entity', value: undefined });
                    updateCCH({ key: 'workbook', value: undefined });
                  }
                }}
                color="primary"
              />
            }
            label={clientData.isNew ? 'New client' : 'Existing client'}
          />
        </Grid>
      </Grid>
      {!clientData.isNew && (
        <Grid spacing={2} container>
          <Grid item xs={4}>
            <Autocomplete
              options={entities}
              getOptionLabel={(option: Entity) => option.name}
              value={data.entity}
              onChange={(e, value) => {
                if (value?.name && value?.entityId) {
                  fetchWorkbooks(value.entityId);
                  setData({ ...data, entity: value });
                  updateCCH({ key: 'entity', value: value });
                  updateCCH({ key: 'workbook', value: undefined });
                }
                if (!value) {
                  setData({ ...data, entity: null, workbook: null });
                  updateCCH({ key: 'entity', value: undefined });
                  updateCCH({ key: 'workbook', value: undefined });
                }
              }}
              style={{ width: '100%' }}
              renderInput={(params: any) => (
                <TextField {...params} label="Entity" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={workbooks.filter((f) => f.entityId === data?.entity?.entityId)}
              getOptionLabel={(option: Workbook) => option.name}
              value={data.workbook}
              onOpen={() => updateCCH({ key: 'workbook', value: undefined })}
              onChange={(e, value) => {
                updateCCH({ key: 'workbook', value: value || undefined });
                setData({ ...data, workbook: value || null });
              }}
              style={{ width: '100%' }}
              renderInput={(params: any) => (
                <TextField {...params} label="Workbook" variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <p>Do you want to use a predefined mapping template?</p>
          <FormControlLabel
            control={
              <Switch
                checked={data.useTemplate}
                onChange={() => {
                  setData({ ...data, useTemplate: !data.useTemplate, template: null });
                  updateCCH({ key: 'useTemplate', value: !cchData.useTemplate });
                  updateCCH({ key: 'template', value: undefined });
                  templates.length === 0 && fetchTemplates();
                }}
                color="secondary"
              />
            }
            label={cchData.useTemplate ? 'Use mapping template' : 'No mapping template'}
          />
        </Grid>
      </Grid>
      {cchData.useTemplate && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Autocomplete
              options={templates}
              value={data.template}
              getOptionLabel={(option: Template) => option.name}
              onChange={(e, value) => {
                updateCCH({ key: 'template', value: value || undefined });
                setData({ ...data, template: value || null });
              }}
              style={{ width: '100%' }}
              renderInput={(params: any) => (
                <TextField {...params} label="Templates" variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      )}
      <p>User information</p>
      <form autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Preparer"
              variant="outlined"
              size="small"
              type="email"
              fullWidth
              value={clientData.preparer}
              onChange={(e) => updateClient('preparer', e.target.value, false)}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Reviewer"
              variant="outlined"
              size="small"
              type="email"
              fullWidth
              value={clientData.reviewer}
              onChange={(e) => updateClient('reviewer', e.target.value, false)}
            />
          </Grid>
        </Grid>
        <p>
          Filling in the reviewer name is optional: If you don’t fill in the reviewer it is
          automatically the same person as the indicated preparer.
        </p>
      </form>
    </>
  );
};

export default CCHView;
