import { makeStyles } from "@material-ui/core"

export const StatusGradient = [
  "#c52a1a",
  "#d14317",
  "#db5913",
  "#e56e0f",
  "#ed820c",
  "#f4970d",
  "#faab14",
  "#ffbf1f",
  "#e2bc09",
  "#c5b800",
  "#a8b400",
  "#8bae07",
  "#6da817",
  "#4da123",
  "#22992e"
]
export const useStyles = makeStyles({
  sessionGrid: {
    fontFamily: "PwC Helvetica Neue",
    fontSize: 14, 
    "& b": {
      color: "#D93954"
    }
  }
})