//import { getData, postData, putData } from '@pwc-fi/ui-library';
import { makeAutoObservable, runInAction } from 'mobx';
import { getData, postData, putData } from 'services/ApiActions';
import { getTokenContents } from 'services/Jwt';
import {
  ISessionStore,
  Client,
  CCHData,
  ShareFiles,
  Entity,
  Workbook,
  Template,
  Category,
  Account,
  CCHItem,
  AIResult,
  StepItem,
  BalanceSession,
} from 'types/Store';
import { v4 as uuidv4 } from 'uuid';

const initSteps: StepItem[] = [
  {
    label: 'Client details',
    description:
      'Fill in Job ID and fetch with it the client information. You can also insert manually the information (client name, business ID, address, city/area, post code and country). Fill in information manually if e.g. the company is a subsidiary and its iPower code gives information of the parent company. Select the start and end date of the financial period and choose, if the engagement letter contains data clause and if this is a test.',
    instructions: 'Proceed to CCH settings',
    completed: false,
    skip: false,
  },
  {
    label: 'CCH settings',
    description:
      'In this section you define CCH settings for the robot and indicate if you want to use a mapping template.',
    instructions: 'Proceed to TB upload',
    completed: false,
    skip: false,
  },
  {
    label: 'Upload trial balance',
    description:
      'Upload the trial balance files(s). The cleaned version is mandatory. It has to be in .csv format. Please also upload the original file received from the client: in the future we will use AI to clean up this version. It can be in any format',
    instructions: 'Proceed to data synchronizing',
    completed: false,
    skip: false,
  },
  {
    label: 'Synchronise data',
    description: 'Upload files and session data',
    instructions: 'Please wait while we synchronise all data with the backend',
    completed: false,
    skip: false,
  },
  {
    label: 'Accounts',
    description:
      'On this page you can see the  AI mapping results and make corrections if needed. Quick proceed at the bottom of this page.',
    instructions:
      'If mappings are ok, press "proceed" to submit the data to the robot. After this you cannot return to the previous steps.',
    completed: false,
    skip: false,
  },
  {
    label: 'Complete',
    description: 'Submit all data to the robot queue',
    instructions: '',
    completed: false,
    skip: false,
  },
];

class SessionStore implements ISessionStore {
  sessionId: string = uuidv4();
  clientData: Client = {
    jobId: '',
    isNew: false,
    isTest: false,
    dataClause: false,
    details: {},
  };
  cchData: CCHData = {
    useTemplate: false,
  };
  files: ShareFiles = {};

  steps = initSteps;
  entities = new Array<Entity>();
  workbooks = new Array<Workbook>();
  templates = new Array<Template>();
  categories = new Array<Category>();
  accounts = new Array<Account>();
  sessions = new Array<BalanceSession>();

  constructor() {
    makeAutoObservable(this);
    this.fetchCategories();
  }
  initSessionId() {
    this.sessionId = uuidv4();
  }
  initStatus() {
    this.fetchSessions();
  }
  get getBalanceSession() {
    return {
      balanceSessionId: this.sessionId,
      businessId: this.clientData.businessId,
      jobId: this.clientData.jobId,
      clientName: this.clientData.clientName,
      address: `${this.clientData.details?.address}, ${this.clientData.details?.postCode} ${this.clientData.details?.postArea}, ${this.clientData.details?.country}`,
      dataClause: this.clientData.dataClause,
      isTest: this.clientData.isTest,
      isNew: this.clientData.isNew,
      isSubmitted: false,
      status: 0,
      useTemplate: this.cchData.useTemplate,
      entity: this.cchData.entity?.name,
      workbook: this.cchData.workbook?.name,
      template: this.cchData.template?.name,
      periodStart: this.clientData.periodStart?.toDateString(),
      periodEnd: this.clientData.periodEnd?.toDateString(),
      preparer: this.clientData.preparer,
      reviewer: this.clientData.reviewer,
      userId: this.getUserId(),
    };
  }
  updateClient(key: string, value: string | Date | boolean, detail: boolean) {
    if (detail)
      this.clientData = {
        ...this.clientData,
        details: { ...this.clientData.details, [key]: value },
      };
    else this.clientData = { ...this.clientData, [key]: value };
  }
  updateCCH(item: CCHItem) {
    if (item.key === 'template') this.updateStep(4, 'skip', item.value ? true : false);
    this.cchData = { ...this.cchData, [item.key]: item.value };
  }
  updateStep(id: number, key: string, value: string | boolean) {
    this.steps = this.steps.map((step, index) => (index === id ? { ...step, [key]: value } : step));
  }
  updateFile(key: string, file: File) {
    this.files = { ...this.files, [key]: file };
  }
  updateSessionId(sessionId: string) {
    this.sessionId = sessionId;
  }
  updatePreparer() {
    let token = localStorage.getItem('id_token');
    if (token) {
      this.clientData.preparer = getTokenContents(token).name;
    }
  }
  getUserId() {
    let token = localStorage.getItem('id_token');
    return token ? getTokenContents(token).uid : '';
  }
  async fetchClientData(jobId: string) {
    this.updatePreparer();
    let response = await getData<Client>(`client/${jobId}`);
    if (!response.isError)
      runInAction(() => {
        this.clientData = { ...this.clientData, ...response.data };
      });
  }
  async fetchAccountResults(sessionId: string) {
    await this.fetchCategories();
    if (this.accounts.filter((f) => f.sessionId === sessionId).length > 0) return;
    let response = await getData<Account[]>(`account/results/${sessionId}`);
    if (!response.isError)
      runInAction(() => {
        if (response.data) {
          this.accounts = response.data;
        }
      });
    else
      console.log(response)
  }
  async fetchEntities() {
    let response = await getData<Entity[]>(`cch/entities`);
    if (!response.isError)
      runInAction(() => {
        if (response.data) this.entities = response.data;
      });
  }
  async fetchWorkbooks(entityId: number) {
    let response = await getData<Workbook[]>(`cch/workbooks/${entityId}`);
    if (!response.isError)
      runInAction(() => {
        if (response.data) this.workbooks = response.data;
      });
  }
  async fetchTemplates() {
    let response = await getData<Template[]>(`cch/templates`);
    if (!response.isError)
      runInAction(() => {
        if (response.data) this.templates = response.data;
      });
  }
  async fetchCategories() {
    if (this.categories.length > 0) return;
    let response = await getData<Category[]>(`cch/categories`);
    if (!response.isError)
      runInAction(() => {
        if (response.data) this.categories = response.data;
      });
  }
  async fetchSessions() {
    let response = await getData<BalanceSession[]>(`session/all`);
    if (!response.isError)
      runInAction(() => {
        if (response.data) this.sessions = response.data;
      });
  }
  async updateAIResult(result: AIResult) {
    let response = await putData<AIResult, AIResult>(`account/update`, result);
    if (!response?.isError)
      runInAction(() => {
        if (response.data)
          this.accounts = this.accounts.map((account) =>
            account.accountId === result.accountId ? { ...account, result: result } : account
          );
      });
  }
  async abandonSession(sessionId: string) {
    let response = await postData<object, boolean>(`session/abandon/${sessionId}`, {});
    await this.fetchSessions();
    return !response?.isError;
  }
  async postAll(sessionId: string) {
    let response = await postData<object, boolean>(`session/submit/${sessionId}`, {});
    return !response?.isError;
  }
}
export default SessionStore;
