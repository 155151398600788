import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import EditIcon from '@material-ui/icons/Edit';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';

import ClientView from 'components/steps/client/ClientView';
import CCHView from 'components/steps/CCH/CCHView';
import UploadView from 'components/steps/upload/UploadView';
import AccountView from 'components/steps/Accounts/AccountView';
import SyncView from 'components/sync/SyncView';
import CompleteView from 'components/steps/Complete/CompleteView';
import { Authenticate, PageHeader } from '@pwc-fi/ui-library';
import { StepItem, StoreProps } from 'types/Store';
import { ViewList, HelpOutline } from '@material-ui/icons';
import Authentication from 'components/Authentication/Authentication';

const Container: React.FC<StoreProps & RouteComponentProps> = ({
  shareStore,
  location,
  match,
}) => {
  const [activeStep, setStep] = useState(location.pathname.includes('accounts') ? 4 : 0);
  const history = useHistory();
  const accountsRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const stepLocations = [
      'client',
      'cch',
      'upload',
      'sync',
      'accounts',
      'submit'
    ]
    if(!location.pathname.includes(stepLocations[activeStep]))
      history.push(`/${stepLocations[activeStep]}/${activeStep > 0 ? shareStore.sessionId : ""}`);
    if(activeStep === 4)
    {
      window.scrollTo({
        top: accountsRef.current?.getBoundingClientRect().top,
        behavior: 'smooth'
      })
    }
  }, [ shareStore.sessionId, activeStep, history, location.pathname ]);

  const setComplete = (id: number, status: boolean) => {
    if (shareStore.steps[id].completed !== status) shareStore.updateStep(id, 'completed', status);
  };

  const getNextStep = (steps: StepItem[], step: number) => {
    let nextStep = steps[step + 1].skip ? (steps[step + 2].skip ? step + 3 : step + 2) : step + 1;
    return nextStep;
  };

  const getStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <ClientView
            clientData={shareStore.clientData}
            fetchClient={(jobId) => shareStore.fetchClientData(jobId)}
            updateClient={(key, value, detail) => shareStore.updateClient(key, value, detail)}
            complete={(status) => setComplete(step, status)}
          />
        );
      case 1:
        return (
          <CCHView
            clientData={shareStore.clientData}
            cchData={shareStore.cchData}
            entities={shareStore.entities}
            workbooks={shareStore.workbooks}
            templates={shareStore.templates}
            fetchEntities={() => shareStore.fetchEntities()}
            fetchTemplates={() => shareStore.fetchTemplates()}
            fetchWorkbooks={(entityId) => shareStore.fetchWorkbooks(entityId)}
            updateCCH={(item) => shareStore.updateCCH(item)}
            updateClient={(key, value, detail) => shareStore.updateClient(key, value, detail)}
            complete={(status) => setComplete(step, status)}
          />
        );
      case 2:
        return (
          <UploadView
            files={shareStore.files}
            updateFile={(key, file) => shareStore.updateFile(key, file)}
            complete={(status) => setComplete(step, status)}
          />
        );
      case 3:
        return (
          <SyncView
            sessionId={shareStore.sessionId}
            getBalanceSession={shareStore.getBalanceSession}
            files={shareStore.files}
            complete={(status) => setComplete(step, status)}
          />
        );
      case 4:
        // update session id just in case
        shareStore.updateSessionId(location.pathname.split("/")[2].toLocaleLowerCase());
        return (
          <AccountView
            sessionId={location.pathname.split("/")[2].toLocaleLowerCase()}
            clientData={shareStore.clientData}
            accounts={shareStore.accounts}
            categories={shareStore.categories}
            fetchAccountResults={(sessionId) => shareStore.fetchAccountResults(sessionId)}
            fetchCategories={() => shareStore.fetchCategories()}
            updateAIResult={(result) => shareStore.updateAIResult(result)}
            complete={(status) => setComplete(step, status)}
          />
        );
      case 5:
        return (
          <CompleteView
            sessionId={shareStore.sessionId}
            postAll={shareStore.postAll}
            complete={(status) => setComplete(step, status)}
          />
        );
    }
  };
  return (
    <>
      <Authentication/>
      <PageHeader
        homePage
        title="Project share"
        actionContent={
          <>
            <Button
              startIcon={<ViewList />}
              color="primary"
              variant="outlined"
              size="large"
              disableElevation
              href="/status"
            >
              View sessions
            </Button>
          </>
        }
      >
        <p>
          On this page you can trigger an automated CIT return process in Service Portal. Robot
          executes all actions in CCH according to the information you fill on this portal.
        </p>
        <p>
          You can create clients and workbooks, bring data clauses into use for new and existing CIT
          clients, upload TB for new clients, roll forward workbooks, upload TBs and mapping
          templates for existing clients and map customer trial balances.
        </p>
        <Button
          variant="outlined"
          startIcon={<HelpOutline />}
          color="primary"
          disableElevation
          size="large"
          href="https://docs.google.com/presentation/d/1WT0YGhGKzwCaerX3A8M-CPXtSja8AUj-oZA63WY2TRk/edit?usp=sharing"
          target="_blank"
          style={{ marginBottom: 20 }}
        >
          Instructions
        </Button>
      </PageHeader>
      <Stepper activeStep={activeStep} orientation="vertical">
        {shareStore.steps.map((step, index) => (
          <Step key={step.label} active={activeStep === index} ref={ activeStep === 4 ? accountsRef : null}>
            <StepLabel>
              <Grid container alignContent="center" spacing={2}>
                <Grid item xs={11}>
                  <h3>{step.label}</h3>
                  <p>{activeStep === index && step.description}</p>
                </Grid>
                <Grid item xs={1}>
                  {!shareStore.steps[3].completed && step.completed && index !== activeStep && (
                    <Button
                      disableElevation
                      startIcon={<EditIcon />}
                      color="primary"
                      size="large"
                      onClick={() => setStep(index)}
                    >
                      Edit
                    </Button>
                  )}
                </Grid>
              </Grid>
            </StepLabel>
            {index === activeStep && (
              <StepContent>
                {getStep(index)}
                {shareStore.steps.length !== activeStep + 1 && (
                  <Connector
                    step={shareStore.steps[activeStep]}
                    complete={step.completed}
                    nextStep={() => setStep(getNextStep(shareStore.steps, activeStep))}
                  />
                )}
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>
    </>
  );
};
interface ConnectorProps {
  step: StepItem;
  complete: boolean;
  nextStep: () => void;
}
const Connector: React.FC<ConnectorProps> = ({ step, complete, nextStep }) => (
  <div style={{ padding: 16, marginTop: 16, marginBottom: 16, border: '1px solid grey' }}>
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={11} style={{ textAlign: 'center' }}>
        {step.instructions}
      </Grid>
      <Grid item xs={1}>
        <Button
          variant="contained"
          disableElevation
          size="large"
          color="primary"
          disabled={!complete}
          onClick={nextStep}
        >
          Proceed
        </Button>
      </Grid>
    </Grid>
  </div>
);
export const StepContainer = inject('shareStore')(observer(Container));
