import { LinearProgress } from '@material-ui/core'
import { FC, useEffect, useState } from 'react'

interface CompleteViewProps {
  sessionId: string,
  postAll: (sessionId: string) => Promise<boolean>,
  complete: (status: boolean) => void
}

const CompleteView: FC<CompleteViewProps> = ({ postAll, sessionId }) => {
  const [complete, setComplete] = useState(false)
  useEffect(() => {
    const submitData = async () => {
      if(await postAll(sessionId))
        setComplete(true)
    }
    if(!complete)
      submitData()
  }, [complete, postAll, sessionId])
  return(
    complete
    ? <>
      <h2>Done!</h2>
      <h3>Data submitted successfully</h3>
      <p>Your request will now be processed by the robot.</p>
    </>
    : <>
      <LinearProgress color="primary"/>
      <h2>Sending data to the robot queue</h2>
    </>
  )
}
export default CompleteView