import { Container, LinearProgress, Paper } from '@material-ui/core';
import { Login, NavBar } from '@pwc-fi/ui-library';
import { FC } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { StepContainer } from 'containers/Stepper/StepperContainer';
import { StatusContainer } from 'containers/Status/StatusContainer';
import LoginContainer from 'containers/Login/LoginContainer';
import Authentication from './Authentication/Authentication';

export const Layout: FC = () => (
  <Router>
    <NavBar />
    <Container maxWidth="lg">
      <Switch>
        <Route path="/status" exact component={StatusContainer} />
        <Route path="/status/:id" exact component={StatusContainer} />
        <Route
          path="/login"
          exact
          component={LoginContainer}
        />
        <Route path="/" component={StepContainer} />
      </Switch>
    </Container>
  </Router>
);
