import {
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Header, Order } from 'types/Common';
import ConfidenceMeter from './ConfidenceMeter';
import TableHeader from './TableHeader';
import { sumFloats } from 'services/Common';
import { Account, Category, Client, AIResult } from 'types/Store';

interface AccountViewProps {
  sessionId: string;
  accounts: Account[];
  categories: Category[];
  clientData: Client;
  fetchAccountResults: (sessionId: string) => void;
  fetchCategories: () => void;
  updateAIResult: (result: AIResult) => void;
  complete: (status: boolean) => void;
}

const tableSort = (array: Account[], orderBy: string, order: Order) => {
  switch (orderBy) {
    case 'account':
      return array.sort((a, b) =>
        order === 'asc' ? (a.account > b.account ? 1 : -1) : a.account < b.account ? 1 : -1
      );
    case 'number':
      return array.sort((a, b) =>
        order === 'asc' ? (a.number > b.number ? 1 : -1) : a.number < b.number ? 1 : -1
      );
    case 'ai':
      return array.sort((a, b) =>
        a.result?.result && b.result?.result
          ? order === 'asc'
            ? a.result?.result > b.result?.result
              ? 1
              : -1
            : a.result?.result < b.result?.result
            ? 1
            : -1
          : -1
      );
    case 'confidence':
      return array.sort((a, b) =>
        a.result?.confidence && b.result?.confidence
          ? order === 'asc'
            ? a.result?.confidence > b.result?.confidence
              ? 1
              : -1
            : a.result?.confidence < b.result?.confidence
            ? 1
            : -1
          : -1
      );
    case 'value':
      return array.sort((a, b) =>
        order === 'asc' ? (a.value > b.value ? 1 : -1) : a.value < b.value ? 1 : -1
      );
    default:
      return array;
  }
};

const tableFilter = (array: Account[], filter: string) => {
  if (filter.length < 2) return array;
  let regexp = new RegExp(filter, 'gi');
  let result = new Array<Account>();
  array.forEach((item) => {
    for (const [key, value] of Object.entries(item)) {
      if (typeof value === 'string') if (value.match(regexp)) result.push(item);
    }
  });
  return result;
};

const headers: Header[] = [
  { id: 'number', numeric: false, label: 'Number', sortable: true },
  { id: 'account', numeric: false, label: 'Account', sortable: true },
  { id: 'value', numeric: true, label: 'Amount', sortable: true },
  { id: 'confidence', numeric: false, label: 'Confidence', sortable: true },
  { id: 'ai', numeric: false, label: 'Result', sortable: false },
  { id: 'manual', numeric: false, label: 'Manual select (TLS mapping template)', sortable: false },
];

type ListFilter = {
  key: string;
  value: string;
};

const AccountView: FC<AccountViewProps> = ({
  accounts,
  categories,
  clientData,
  complete,
  fetchAccountResults,
  fetchCategories,
  sessionId,
  updateAIResult,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('account');
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<ListFilter[]>(new Array<ListFilter>());

  const handleSort = (id: string) => {
    const isAsc = orderBy === id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(id);
  };
  useEffect(() => {
    if (!loaded) {
      fetchAccountResults(sessionId);
      setLoaded(true);
    } else complete(true);
  }, [complete, setLoaded, loaded, fetchAccountResults, fetchCategories, sessionId]);

  return !loaded || accounts.length === 0 ? (
    <LinearProgress />
  ) : (
    <>
      <h2>{clientData.clientName}</h2>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            label="Filter by account number"
            variant="outlined"
            size="small"
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table aria-label="Accounts and AI results" size="small">
          <TableHeader headers={headers} orderBy={orderBy} order={order} requestSort={handleSort} />
          <TableBody>
            {accounts &&
              accounts.length > 0 &&
              tableFilter(tableSort(accounts, orderBy, order), search).map((account) => (
                <TableRow key={account.accountId}>
                  <TableCell>{account.number}</TableCell>
                  <TableCell>{account.account}</TableCell>
                  <TableCell
                    align="right"
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {account.value} &euro;
                  </TableCell>
                  <TableCell>
                    <ConfidenceMeter
                      value={(account.result?.confidence || 0) * 100}
                      corrected={account.result?.categoryId ? true : false}
                    />
                  </TableCell>
                  <TableCell style={{ textTransform: 'capitalize' }}>
                    {account.result
                      ? account.result.categoryId
                        ? 'Manually selected'
                        : account.result.result
                      : 'No'}
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      options={categories.sort((a, b) => (a.code > b.code ? 1 : -1))}
                      groupBy={(option) => option.subCategory}
                      getOptionLabel={(option) => option.name}
                      style={{ width: 300 }}
                      onChange={(e, value) =>
                        value
                          ? account.result
                            ? updateAIResult({
                                ...account.result,
                                categoryId: value.categoryId,
                              })
                            : updateAIResult({
                                accountId: account.accountId,
                                sessionId: sessionId,
                                result: 'No result',
                                confidence: 0.0,
                                resultId: uuidv4(),
                                categoryId: value.categoryId,
                              })
                          : account.result &&
                            updateAIResult({
                              ...account.result,
                              categoryId: undefined,
                            })
                      }
                      defaultValue={
                        categories.filter((f) => f.categoryId === account.result?.categoryId)[0]
                      }
                      renderOption={(option) => (
                        <>
                          <span style={{ width: 215, fontSize: 11, marginRight: 10 }}>
                            {option.name}
                          </span>
                          <b style={{ width: 75, fontSize: 10 }}>{option.code}</b>
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} size="small" label="Correct" variant="outlined" />
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
            {filter.length === 0 && accounts.length > 0 && (
              <TableRow>
                <TableCell>
                  <b>Sum</b>
                </TableCell>
                <TableCell></TableCell>
                <TableCell
                  align="right"
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {sumFloats(accounts.map((m) => m.value)).toFixed(2)} &euro;
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default AccountView;
