import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { StatusGradient } from 'styles/Default'

const useStyles = makeStyles({
  meterWrapper: {
    backgroundColor: '#F2F2F2',
    borderRadius: 5,
    boxShadow: "inset rgb(230 230 230) 0px 0px 7px 0px"
  },
  meterIndicator: {
    textAlign: 'center',
    padding: '0.5em',
    marginRight: 'auto',
    marginLeft: 0,
    color: 'white',
    borderRadius: 5,
    fontSize: 12
  }
})

const ConfidenceMeter: FC<{ value: number, corrected: boolean }> = ({ value, corrected }) => {
  const classes = useStyles()
  return(
    <div
      className={classes.meterWrapper}
    >
      <div
        className={classes.meterIndicator}
        style={{
          width: `${value}%`,
          backgroundColor: StatusGradient[Math.floor((value/100)*15)],
          opacity: corrected ? 0.5 : 1
        }}
      >
        { value.toFixed(0) + '%' }
      </div>
    </div>
  )
}

export default ConfidenceMeter