import { Button, Chip, FormControlLabel, Grid, Switch, TextField } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import BusinessIcon from '@material-ui/icons/Business';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { InputAdornment } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Client } from 'types/Store';

interface ClientViewProps {
  clientData: Client;
  fetchClient: (jobId: string) => void;
  updateClient: (key: string, value: string | boolean | Date, detail: boolean) => void;
  complete: (status: boolean) => void;
}
//83106
const ClientView: FC<ClientViewProps> = ({ clientData, complete, fetchClient, updateClient }) => {
  const [manual, setManual] = useState(false);
  useEffect(() => {
    if (clientData.jobId && clientData.clientName && clientData.periodEnd && clientData.periodStart)
      complete(true);
    else complete(false);
  }, [clientData, complete]);
  const handleChange =
    (prop: string, detail: boolean = false) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateClient(prop, e.target.value, detail);
    };
  return (
    <>
      <Grid container spacing={2} style={{ marginTop: 8, marginBottom: 8 }}>
        {manual ? (
          <>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                defaultValue={clientData.jobId}
                InputProps={{
                  startAdornment: <InputAdornment position="start">JOBID:</InputAdornment>,
                }}
                onChange={handleChange('jobId')}
                required
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Client name"
                variant="outlined"
                size="small"
                defaultValue={clientData.clientName}
                onChange={handleChange('clientName')}
                required
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                label="Business id"
                variant="outlined"
                size="small"
                defaultValue={clientData.businessId}
                onChange={handleChange('businessId')}
                required
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Address"
                variant="outlined"
                size="small"
                defaultValue={clientData.details.address}
                onChange={handleChange('address', true)}
                required
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="City/Area"
                variant="outlined"
                size="small"
                defaultValue={clientData.details.postArea}
                onChange={handleChange('postArea', true)}
                required
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Post code"
                variant="outlined"
                size="small"
                defaultValue={clientData.details.postCode}
                onChange={handleChange('postCode', true)}
                required
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Country"
                variant="outlined"
                size="small"
                defaultValue={clientData.details.country}
                onChange={handleChange('country', true)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                disableElevation
                size="small"
                onClick={() => setManual(false)}
              >
                Close
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                size="small"
                defaultValue={clientData.jobId}
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">JOBID:</InputAdornment>,
                }}
                onChange={handleChange('jobId')}
                required
              />
            </Grid>
            <Grid item xs={10}>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                disableElevation
                onClick={() => clientData.jobId.length > 0 && fetchClient(clientData.jobId)}
              >
                Fetch
              </Button>
              <Button
                variant="contained"
                disableElevation
                size="small"
                onClick={() => setManual(true)}
                style={{ marginLeft: 16 }}
              >
                Edit / manual insert
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      {clientData?.businessId ? (
        <>
          <div style={{ marginTop: 16 }}>
            <Chip
              color="primary"
              avatar={<BusinessIcon />}
              label={`${clientData.clientName} (${clientData.businessId})`}
            />
            <Chip
              color="secondary"
              avatar={<LocationOnIcon />}
              label={`${clientData.details?.address || 'Address'}, ${
                clientData.details?.postCode || 'Postcode'
              } ${clientData.details?.postArea || 'Area/City'}, ${
                clientData.details?.country || 'Country'
              }`}
            />
          </div>
        </>
      ) : (
        !manual && <p>Fill in the job code and fetch the information from M-Files</p>
      )}
      <p>Select the start and end dates of the financial period.</p>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <KeyboardDatePicker
              label="Start"
              inputVariant="outlined"
              size="small"
              disabled={clientData?.clientName === undefined}
              InputLabelProps={{
                shrink: true,
              }}
              value={clientData.periodStart}
              format="dd/MM/yyyy"
              onChange={(value) => {
                if (value) updateClient('periodStart', value, false);
              }}
              required
            />
          </Grid>
          <Grid item xs={8}>
            <KeyboardDatePicker
              label="End"
              size="small"
              inputVariant="outlined"
              disabled={clientData?.clientName === undefined}
              InputLabelProps={{
                shrink: true,
              }}
              value={clientData.periodEnd}
              format="dd/MM/yyyy"
              onChange={(value) => {
                if (value) updateClient('periodEnd', value, false);
              }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            Note! If the exact reporting period is not found in the CCH portal, the robot fills in
            RPA test reporting period details.
          </Grid>
          <Grid item xs={6}>
            <p>Does the engagement letter contain data clause?</p>
            <FormControlLabel
              control={
                <Switch
                  checked={clientData.dataClause}
                  onChange={() => {
                    updateClient('dataClause', !clientData.dataClause, false);
                  }}
                  color="primary"
                />
              }
              label={clientData.dataClause ? 'Yes' : 'No'}
            />
          </Grid>
          <Grid item xs={6}>
            <p>Is this a test?</p>
            <FormControlLabel
              control={
                <Switch
                  checked={clientData.isTest}
                  onChange={() => {
                    updateClient('isTest', !clientData.isTest, false);
                  }}
                  color="primary"
                />
              }
              label={clientData.isTest ? 'Yes' : 'No'}
            />
            <p>If you choose yes, the robot will add to the workbook information "test"</p>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
};
export default ClientView;
