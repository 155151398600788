import Papa from 'papaparse';
import XLSX from 'xlsx'

import { ErrorLevel, ValidationError } from 'types/Common';
import { Account, Category } from 'types/Store';

const minimumDiscrepancy = 0.01;
const specialCharacters = /[`!@#^*_=;':"\\?~]/;

export const validateEmail = (value: string) => {
  let mailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return mailRegex.test(value);
};

export const handleDate = (date: string | undefined) =>
  date &&
  new Date(date).toLocaleDateString('en-EN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
export const sumFloats = (array: number[]) => {
  return array.reduce((a, b) => a + b) / 100;
};

export const parseAmount = (amount: string) => {
  amount = amount.replace(/ /g, '');
  if (amount.includes(',')) {
    let splitAmount = amount.split(',');
    if (splitAmount[1].length === 1) splitAmount[1] = splitAmount[1] + '0';
    return parseInt(splitAmount.join(''));
  } else return parseInt(amount) * 100;
};

const parseCsv = async (file: File) =>
  new Promise((resolve, reject) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      delimiter: ";",
      complete: (results) => {
        return resolve(results.data);
      },
      error: (error) => {
        return reject(error);
      },
    });
  });

export const unparseAccounts = (data: Account[], categories: Category[]) => {
  let rows = (data.map(m => ({number: m.number, account: m.account, value: m.value.toString(), confidence: m.result?.confidence.toString() || "N/A", result: m.result?.result || "", manual: m.result ? categories.find(f => f.categoryId === m.result?.categoryId)?.name || "" : "" })));
  let excelData = [Object.keys(rows[0])];
  rows.forEach(m => {
    excelData.push(Object.values(m))
  })
  let worksheet = XLSX.utils.aoa_to_sheet(excelData);
  worksheet['!cols'] = [
    {wch: 8},
    {wch: 32},
    {wch: 16},
    {wch: 18},
    {wch: 48},
    {wch: 48}
  ]
  let workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Mapping");
  let buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  return new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
}

// export const unparseAccounts = (data: Account[]) => Papa.unparse(data.map(m => ({number: m.number, account: m.account, value: m.value, confidence: m.result?.confidence, result: m.result?.result })))

const validator = (results: string[][]) => {
  let errors = new Array<ValidationError>();
  let itemCheck = new Array<string>();
  if (Array.isArray(results)) {
    results.forEach((item, index) => {
      if (Array.isArray(item)) {
        if (item.length !== 3)
          errors.push({
            level: ErrorLevel.ERROR,
            message: `Row ${index + 1} does not contain three columns`,
          });
        if (itemCheck.includes(item[0])) {
          errors.push({
            level: ErrorLevel.ERROR,
            message: `Row ${index + 1} contains duplicate account number`,
          });
        }
        if (item.includes(''))
          errors.push({
            level: ErrorLevel.ERROR,
            message: `Row ${index + 1} contains null value`,
          });
        if (specialCharacters.test(item[0]) || specialCharacters.test(item[1]))
          errors.push({
            level: ErrorLevel.ERROR,
            message: `Row ${index + 1} contains special characters or a formula`,
          });
        itemCheck.push(item[0]);
      }
    });
    let sum = sumFloats(results.map((m: string[]) => parseAmount(m[2])));
    if (Math.abs(sum) > minimumDiscrepancy) {
      errors.push({
        level: ErrorLevel.WARNING,
        message: `Sum not zero: ${sum.toFixed(2)}`,
      });
    }
  } else
    errors.push({
      level: ErrorLevel.ERROR,
      message: 'File unreadable',
    });

  return errors;
};

export const validate = async (file: File) => {
  try {
    const result = await parseCsv(file);
    if (Array.isArray(result)) return validator(result);
  } catch (error) {
    console.log(error);
  }
  return [
    {
      level: ErrorLevel.ERROR,
      message: 'File unreadable',
    },
  ];
};
