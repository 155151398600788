import { Paper, Chip } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'

interface DropzoneProps {
  files?: File[],
  multiple?: boolean,
  validator?: (file: File) => Promise<boolean>,
  onChange: (files: File[]) => void
}

const Dropzone:FC<DropzoneProps> = ({ onChange, multiple=false, files=new Array<File>(), validator }) => {
  const [fileArray, setFileArray] = useState(files)
  const [hover, setHover] = useState(false)

  useEffect(() => {
    onChange(fileArray)
  }, [fileArray, onChange])

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setHover(true)
  }
  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setHover(false)
  }
  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if(!multiple && fileArray.length === 1) return
    e.preventDefault()
    handleFiles(e.dataTransfer.files)
    setHover(false)
  }
  const handleFiles = async (filelist: FileList) => {
    if(filelist.length)
    {
      let tempFiles = new Array<File>()
      for(let i = 0; i < filelist.length; i++)
      {
        if(!validator)
          tempFiles.push(filelist[i])
        else
          if(await validator(filelist[i]))
            tempFiles.push(filelist[i])
      }
      if(tempFiles.length > 0)
        setFileArray(multiple
          ? [...fileArray, ...tempFiles]
          : [...fileArray, tempFiles[0]])
    }
  }
  const handleDelete = (file: File) => {
    setFileArray(fileArray.filter(f => f !== file))
  }
  return(
    <Paper
      variant="outlined"
      style={{textAlign: 'center', padding: 16, backgroundColor: hover ? '#F2F2F2' : '#FFFFFF'}}
      onDragOver={dragOver}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
    >
      {
        fileArray.length > 0 
          ? fileArray.map(file => <Chip label={file.name} key={file.lastModified} onDelete={() => handleDelete(file)}/>)
          : <p>Drag and drop</p>
      }
    </Paper>
  )
}
export default Dropzone