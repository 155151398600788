import jwt_decode from 'jwt-decode'
import { TokenPayload } from 'types/Common'

/**
 * Decode token and return it as an object
 * @param {string} token string 
 * @returns {TokenPayload} decoded token
 */
const getTokenContents = (token: string): TokenPayload => jwt_decode(token)

export {
  getTokenContents
}