import React, { FC } from 'react'
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core'
import { Header, Order } from 'types/Common'

export interface TableHeaderProps {
  headers: Header[],
  order: Order,
  orderBy: string | number,
  requestSort: (id: string) => void
}

const TableHeader: FC<TableHeaderProps> = ({ headers, order, orderBy, requestSort }) => {
  const handleSort = (header: Header) => (event: React.MouseEvent<unknown>) => header.sortable && requestSort(header.id)
  return(
    <TableHead>
      <TableRow>
        {
          headers.map((header) => (
            <TableCell
              key={header.id}
              align={header.numeric ? 'right' : 'left'}
              sortDirection={orderBy === header.id ? order : 'asc'}
            >
              <TableSortLabel
                active={orderBy === header.id}
                direction={orderBy === header.id ? order : 'asc'}
                onClick={handleSort(header)}
              >
                {header.label}
              </TableSortLabel>
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  )
}

export default TableHeader